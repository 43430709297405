import { cn } from '@/utils/cn';

interface HeartProps {
  amount: 1 | 2;
  className?: string;
}

export default function Heart({ amount, className }: HeartProps) {
  const position = getOrnamentPosition(amount);

  return (
    <div className="relative">
      <div className="hidden lg:flex">
        <div className={cn(position, className)}>{getOrnamentSvg(amount, false)}</div>
      </div>
      <div className="lg:hidden">
        <div className={cn(position, className)}>{getOrnamentSvg(amount, true)}</div>
      </div>
    </div>
  );
}

function getOrnamentPosition(amount: 1 | 2) {
  switch (amount) {
    case 1:
      return 'absolute left-[-75px] bottom-[25px] lg:left-[-90px] lg:bottom-[-70px]';
    case 2:
      return 'absolute right-[-10px] top-[25px] lg:right-[-150px] lg:top-[-30px]';
  }
}

function getOrnamentSvg(amount: 1 | 2, isMobile: boolean) {
  switch (amount) {
    case 1:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobile ? '156' : '308'}
          height={isMobile ? '156' : '308'}
          viewBox="0 0 308 308"
          fill="none"
          aria-hidden="true"
          focusable="false"
        >
          <path
            d="M221.996 71.5301C215.39 63.29 203.833 59.0026 191.738 60.379C163.307 63.6938 151.079 96.7445 145.568 123.607C125.177 105.235 95.7564 85.8375 70.8804 99.9972C60.2707 106.064 53.5417 116.345 53.0961 126.897C51.9849 152.887 81.9847 179.291 98.2421 196.17C117.077 213.447 139.176 235.918 171.299 229.892C174.322 229.108 176.971 228.136 179.448 227.117C182.048 226.472 184.758 225.687 187.658 224.529C217.1 210.322 221.669 179.161 226.574 154.102C229.668 130.827 238.23 91.8338 221.949 71.5455"
            fill="#EE0000"
          />
        </svg>
      );

    case 2:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={isMobile ? '94' : '275'}
          height={isMobile ? '72' : '229'}
          viewBox="0 0 275 229"
          fill="none"
          aria-hidden="true"
          focusable="false"
        >
          <path
            d="M127.633 41.0096C98.9072 51.1254 98.2332 73.8727 103.048 95.5852C106.811 112.404 142.824 168.924 157.038 175.489C157.775 175.809 158.482 175.987 159.198 176.114C160.038 176.316 160.975 176.427 161.972 176.359C177.57 175.078 230.746 134.257 240.035 119.74C251.98 101.01 249.91 89.1709 243.187 75.0235L238.959 68.1352C214.474 43.0354 193.944 66.8548 176.488 80.8518C176.488 80.8518 159.94 29.6582 127.575 41.0265"
            fill="#EE0000"
          />
          <path
            d="M41.7961 113.716C25.5355 119.442 25.154 132.319 27.8794 144.61C30.0096 154.13 50.3953 186.125 58.4416 189.841C58.8587 190.022 59.2589 190.123 59.6644 190.195C60.1396 190.309 60.6704 190.372 61.2346 190.334C70.0641 189.608 100.166 166.501 105.424 158.283C112.186 147.68 111.014 140.979 107.208 132.97L104.815 129.071C90.9543 114.863 79.3334 128.346 69.4515 136.27C69.4515 136.27 60.0845 107.29 41.7635 113.726"
            fill="#EE0000"
          />
        </svg>
      );
  }
}
