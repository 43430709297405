/* 
Interpretes Sanity RichText

The various components and marks allow specific Sanity types and marks to be transformed into custom React components for customizable
and extendable RichText content rendering.
*/

import InstagramPost from './InstagramPost';
import Quote from './Quote';
import { contentTypeToPath } from '@/utils/api-helpers';
import { CONTENT_TYPE_PAGE } from '@/shared/constants';
import HIHLink from './Link';
import HIHImageCaption from './ImageCaption';
import { NewTabHint } from './NewTabHint';

const RichTextComponents: any = {
  // The types are used to render specific heuteinhamburg sanity components
  types: {
    hihImage: ({ value }: { value: any }) => {
      return (
        <HIHImageCaption
          className="mb-5xl md:mb-6xl mt-6xl first:mt-0"
          imageClassName="mb-md md:mb-xl rounded-2xl"
          image={value}
          width={1600}
          height={1200}
        />
      );
    },
    hihInstagramPost: ({ value }: { value: { url: string } }) => {
      return (
        <InstagramPost
          className="hihInstagramPost mb-5xl md:mb-6xl mt-6xl first:mt-0"
          urlString={value.url}
        />
      );
    },
    hihQuote: ({
      value,
    }: {
      value: { text: any; title: string; disclaimer: string };
    }) => {
      return (
        <Quote
          className="mb-5xl md:mb-6xl mt-6xl first:mt-0"
          text={value.text}
          title={value.title}
          disclaimer={value.disclaimer}
        />
      );
    },
  },
  // Marks are used to render links. We differentiate between internal and external links
  marks: {
    linkInternal: ({ value, children }: { value: any; children: any }) => {
      const { type, slug } = value;
      const href =
        type === CONTENT_TYPE_PAGE ? `/${slug}` : `/${contentTypeToPath(type)}/${slug}`;
      return (
        <HIHLink href={href} className="text-sys/tertiary/tertiary underline">
          {children}
        </HIHLink>
      );
    },
    linkExternal: ({ value, children }: { value: any; children: any }) => {
      const { blank, href } = value;
      return (
        <HIHLink
          href={href}
          className=" text-sys/tertiary/tertiary underline"
          target={blank ? '_blank' : undefined}
          rel={blank ? 'noopener noreferrer' : undefined}
        >
          {children}
          {blank && <NewTabHint />}
        </HIHLink>
      );
    },
  },
  // Block elements are used to render headings, paragraphs
  block: {
    h2: ({ children }: { children: string }) => (
      <h2 className="font-display text-headline-lg md:text-display-md mb-xl md:mb-4xl mt-6xl first:mt-0">
        {children}
      </h2>
    ),
    h3: ({ children }: { children: string }) => (
      <h3 className="font-display text-headline-md md:text-display-sm mb-lg md:mb-3xl mt-6xl first:mt-0">
        {children}
      </h3>
    ),
    h4: ({ children }: { children: string }) => (
      <h4 className="font-display text-headline-sm md:text-headline-lg mb-md md:mb-xl mt-6xl first:mt-0">
        {children}
      </h4>
    ),
    normal: ({ children }: { children: string }) => (
      <p className="font-body text-body-lg mb-3xl last-of-type:mb-5xl last-of-type:md:mb-6xl">
        {children}
      </p>
    ),
  },
  list: {
    bullet: ({ children }: { children: any }) => (
      <ul className="font-body text-body-lg my-xl ml-2xl list-disc list-outside mb-3xl">
        {children}
      </ul>
    ),
    number: ({ children }: { children: any }) => (
      <ol className="font-body text-body-lg my-xl ml-xl list-decimal list-outside mb-3xl">
        {children}
      </ol>
    ),
  },
};

export default RichTextComponents;
