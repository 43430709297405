import { ARIA_LABEL_MODAL_CLOSE } from '@/shared/constants';
import React, { useEffect, useRef } from 'react';
import HIHButton from './Button';

interface ModalProps {
  isOpen: boolean;
  autoFocus?: boolean;
  handleClose: () => void;
  ariaLabel: string;
  closeLabel: string;
  content: JSX.Element;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  autoFocus,
  handleClose,
  ariaLabel,
  closeLabel,
  content,
}) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  // focus the close button when modal opens
  useEffect(() => {
    if (autoFocus && isOpen) {
      closeButtonRef.current?.focus();
    }
  }, [autoFocus, isOpen]);

  // close on ESC key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, handleClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-sys/primary/primary/50 flex items-center justify-center z-50"
      aria-label={ariaLabel}
      aria-modal="true"
      role="dialog"
    >
      <div className="container-content">
        <div className="py-6xl px-3xl lg:px-8xl bg-sys/primary/on-primary rounded-2xl shadow-2 w-full max-w-screen-lg mx-auto">
          {content}

          <HIHButton
            className="mt-6xl w-full lg:w-[unset]"
            variant="secondary"
            title={closeLabel}
            onClick={handleClose}
            ariaLabel={ARIA_LABEL_MODAL_CLOSE}
            ref={closeButtonRef}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
