'use client';

import { FormEvent, useState } from 'react';
import Image from 'next/image';
import { cn } from '@/utils/cn';
import { TeaserNewsletter as TeaserNewsletterType } from '@/shared/types/teasers';
import HIHRichText from '../base/RichText';
import Modal from '../base/Modal';
import {
  ARIA_LABEL_NEWSLETTER_EMAIL,
  ARIA_LABEL_NEWSLETTER_SUCCESS,
  ERROR_NEWSLETTER_EXISTS,
  ERROR_NEWSLETTER_GENERIC,
} from '@/shared/constants';
import HIHButton from '../base/Button';
import Star from '../base/Star';
import Underline from '../base/Underline';

function SuccessMessage() {
  return (
    <div role="alert" className="lg:flex lg:items-center lg:gap-x-6xl">
      <div>
        <h2 className=" font-display text-headline-lg lg:text-display-lg text-sys/surface/on-surface uppercase">
          Willkommen <Underline variation="singleLine">an Bord!</Underline>
        </h2>
        <p className="mt-2xl text-title-md lg:text-title-lg text-sys/surface/on-surface-variant">
          Prüfe deinen Posteingang und den Spamordner, um deine Anmeldung zu bestätigen.
        </p>
      </div>

      <Image
        className="mt-6xl lg:mt-0 mx-auto"
        src="/images/newsletter.svg"
        width="447"
        height="386"
        alt=""
      />
    </div>
  );
}

export default function TeaserNewsletter({
  teaser,
  className,
}: {
  teaser: TeaserNewsletterType;
  className?: string;
}) {
  const { title, copy, placeholder, disclaimer, buttonLabel } = teaser;
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setIsSubmitting(true);
    setHasError(false);

    const email = (event.currentTarget.elements.namedItem('email') as HTMLInputElement)
      .value;

    try {
      const response = await fetch('/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to add subscriber');
      }

      // success
      if (response.status === 201) {
        setModalContent(<SuccessMessage />);
        setIsModalOpen(true);
      }

      // active | unconfirmed
      if (response.status === 200) {
        setErrorMessage(ERROR_NEWSLETTER_EXISTS);
        setHasError(true);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(ERROR_NEWSLETTER_GENERIC);
        setHasError(true);
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className={className}>
      <Star
        rays={8}
        className="
        -top-[46px] -left-[15px] 
        lg:-left-[30px]  [&_svg]:lg:w-[83.929px] [&_svg]:lg:h-[83.929px]
        xl:-left-[50px] [&_svg]:xl:w-auto [&_svg]:xl:h-auto
      "
      />

      <div
        className={cn(
          ' rounded-2xl px-2xl py-5xl bg-sys/primary/on-primary shadow-2 relative lg:p-7xl lg:flex lg:gap-x-7xl xl:items-end',
        )}
      >
        <div className="lg:basis-1/2">
          <div className="font-display text-headline-lg lg:text-display-sm uppercase">
            {/* {title} */}
            Bleib dran: <br className="hidden lg:block xl:hidden" />
            Der
            <br className="hidden xl:block" /> Heute in Hamburg-
            <Underline variation="singleLine" className="lg:after:!bottom-[-30px]">
              Newsletter
            </Underline>
          </div>
          <p className="mt-xl text-body-lg text-sys/surface/on-surface-variant">
            {/* {copy} */}
            Du bekommst unsere tägliche Auswahl an Freizeit-Empfehlungen
            <br className="hidden xl:block" />
            ganz bequem in dein E-Mail-Postfach. So verpasst du kein Event mehr.
          </p>
        </div>
        <form className="mt-4xl lg:mt-0 lg:basis-1/2" onSubmit={handleSubmit}>
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-xl">
            <div className="relative lg:col-span-8">
              <input
                aria-label={ARIA_LABEL_NEWSLETTER_EMAIL}
                required
                type="email"
                name="email"
                aria-describedby={hasError ? 'emailError' : undefined}
                placeholder={placeholder}
                className={cn(
                  `
                    w-full h-[54px] lg:h-[58px]
                    rounded-xs px-xl py-lg
                    text-body-lg
                    border border-sys/outline/outline text-sys/surface/on-surface-variant
                    focus:ring-2 focus:ring-inset focus:ring-sys/primary/primary
                    focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-sys/primary/primary focus-visible:outline-0
                    hover:border-sys/surface/on-surface
                    disabled:border-sys/surface/on-surface/12 disabled:text-sys/surface/on-surface/12
                  `,
                  hasError &&
                    `border-sys/error/error text-sys/error/error focus:ring-sys/error/error focus-visible:ring-sys/error/error hover:border-sys/error/error pr-5xl`,
                )}
              />

              {hasError && (
                <Image
                  src="/icons/icon-error.svg"
                  alt=""
                  width={24}
                  height={24}
                  className="absolute right-lg top-xl"
                />
              )}
            </div>

            <div className="lg:row-start-2 lg:col-span-8">
              {hasError && (
                <div
                  id="emailError"
                  aria-live="polite"
                  className="text-body-sm text-sys/error/error px-xl py-xs"
                >
                  {errorMessage}
                </div>
              )}

              <HIHRichText
                className="mt-md text-body-md !text-sys/surface/on-surface-variant [&>p]:!mb-0 [&_a]:!text-current"
                value={disclaimer}
              />
            </div>

            <HIHButton
              title={buttonLabel}
              variant="secondary"
              className="mt-xl lg:mt-0 w-full lg:col-span-4 !text-title-md"
              submit
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>

      <div role="alert" aria-live="assertive">
        <Modal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          ariaLabel={ARIA_LABEL_NEWSLETTER_SUCCESS}
          closeLabel="Alles klar"
          content={modalContent}
        />
      </div>
    </div>
  );
}
