/* 
Displays the Instagram posts based on marketing cookie consent or provide an alternative view. 
It uses React hooks to manage state and sets event listeners for Cookiebot updates. This ensures that user 
interactions and cookie settings are respected and handled accordingly.
 */

'use client';

import React, { useEffect, useState } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import HIHButton from './Button';
import { cn } from '@/utils/cn';

interface Cookiebot {
  renew: () => void;
  consent: {
    marketing: boolean;
  };
}

// Extend the global Window interface to include the Cookiebot object
declare global {
  interface Window {
    Cookiebot: Cookiebot;
  }
}

const InstagramPost = ({
  urlString,
  className,
}: {
  urlString: string;
  className?: string;
}) => {
  const [hasMarketing, setHasMarketing] = useState(false);

  useEffect(() => {
    let waitForCookiebotCount = 0;

    // Function to wait for Cookiebot to be available
    function waitForCookiebot() {
      if (
        typeof window.Cookiebot === 'undefined' ||
        typeof window.Cookiebot.consent === 'undefined' ||
        typeof window.Cookiebot.consent.marketing === 'undefined'
      ) {
        if (waitForCookiebotCount < 40) {
          setTimeout(waitForCookiebot, 250);
          waitForCookiebotCount++;
          return;
        }
      } else {
        if (window.Cookiebot.consent.marketing) {
          setHasMarketing(true);
        }
      }
    }

    waitForCookiebot();

    // Handler for Cookiebot consent change events
    const handleConsentChange = () => {
      if (window.Cookiebot.consent.marketing) {
        setHasMarketing(true);
      } else {
        setHasMarketing(false);
      }
    };

    // Listen for Cookiebot consent update events
    window.addEventListener('CookiebotOnAccept', handleConsentChange);
    window.addEventListener('CookiebotOnDecline', handleConsentChange);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('CookiebotOnAccept', handleConsentChange);
      window.removeEventListener('CookiebotOnDecline', handleConsentChange);
    };
  }, []);

  // Return null if no URL is provided
  if (!urlString) {
    return null;
  }

  return (
    <div className={cn('max-w-[500px] flex justify-start', className)}>
      {hasMarketing ? (
        // Display Instagram post if marketing consent is given
        <div className="w-full [&_iframe]:!border-0 [&_iframe]:!mb-0">
          <InstagramEmbed
            url={urlString}
            width={'100%'}
            placeholderProps={
              /* @ts-ignore */
              { style: { border: '0' } }
            }
          />
        </div>
      ) : (
        // Display alternative view if marketing consent is not given
        <div className=" bg-sys/surface/surface-container rounded-lg border border-sys/primary/primary-fixed-dim">
          <div className=" border-b border-sys/primary/primary-fixed-dim p-xl flex w-full items-center">
            <div className="w-[35px] h-[35px] shrink-0 pb-[10px] rounded-full bg-sys/secondary/secondary"></div>
            <div className=" ml-2xl flex justify-between items-center w-full">
              <div>
                <div className="w-[108px] h-[20px] bg-sys/primary/on-primary-fixed-variant "></div>
                <div className="w-[68px] h-[20px] bg-sys/primary/primary-fixed-dim "></div>
              </div>
              <div className="w-[108px] h-[20px] bg-sys/tertiary/tertiary rounded-xs"></div>
            </div>
          </div>
          <div className=" py-6xl px-3xl bg-sys/primary/on-primary-fixed-variant text-sys/primary/on-primary text-center">
            <p className=" font-display text-headline-lg font pb-md">
              Instagram Post blockiert
            </p>
            <p>
              Damit der Instagram Post angezeigt wird, müssen die Marketing-Cookies
              akzeptiert werden.
            </p>
            <div className=" inline-block w-auto pt-3xl">
              <HIHButton
                onClick={() => {
                  window.Cookiebot.renew();
                }}
                title="Cookie-Einstellungen öffnen"
                variant="secondary"
              ></HIHButton>
            </div>
          </div>
          <div className=" p-xl border-b border-sys/primary/primary-fixed-dim">
            <div className="w-[142px] h-[20px] bg-sys/primary/primary-fixed-dim "></div>
          </div>
          <div className=" p-xl">
            <div className="w-[117px] h-[20px] bg-sys/primary/primary-fixed-dim p "></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstagramPost;
