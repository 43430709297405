import React from 'react';
import styles from '@/styles/modules/Underline.module.css';
import { cn } from '@/utils/cn';

interface UnderlineProps {
  children: React.ReactNode;
  variation?: 'singleLine' | 'multiLine';
  className?: string;
}

const Underline: React.FC<UnderlineProps> = ({
  children,
  variation = 'multiLine',
  className,
}) => {
  return (
    <span
      className={cn(
        styles.svgUnderline,
        variation == 'singleLine' ? styles.singleLine : styles.multiLine,
        className,
      )}
    >
      {children}
    </span>
  );
};

export default Underline;
