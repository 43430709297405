import { urlFor } from '@/sanity/sanityClient';
import Image from 'next/image';
import { ImageMeta } from '@/shared/types/imageMeta';
import HIHLink from './Link';
import { cn } from '@/utils/cn';
import { NewTabHint } from './NewTabHint';

interface HIHImageCaptionProps {
  image: ImageMeta;
  alt?: string;
  className?: string;
  imageClassName?: string;
  width: number;
  height: number;
  captionAsOverlay?: boolean;
}

// TODO: required for event detail header, category header, article header, rich text content (event detail, article detail)

const HIHImageCaption: React.FC<HIHImageCaptionProps> = ({
  image,
  className,
  imageClassName,
  width,
  height,
  alt,
  captionAsOverlay = false,
}) => {
  if (!image || image == null || image.image == undefined) {
    return null;
  }

  return (
    <figure className={cn(captionAsOverlay && 'relative', className)}>
      <Image
        src={urlFor(image.image, width, height).url()}
        alt={alt ?? image.alt ?? ''}
        width={width}
        height={height}
        className={imageClassName}
      />
      {image.source && (
        <figcaption
          className={cn(
            '!text-label-sm',
            captionAsOverlay &&
              'absolute bottom-[12px] right-[16px] text-white/60 drop-shadow-[0_1px_1px_rgba(0,0,0,.6)]',
          )}
        >
          {image.sourceUrl ? (
            <HIHLink href={image.sourceUrl} target="_blank" rel="noopener noreferrer">
              {image.source}
              <NewTabHint />
            </HIHLink>
          ) : (
            image.source
          )}
        </figcaption>
      )}
    </figure>
  );
};

export default HIHImageCaption;
