import React from 'react';
import HIHRichText from './RichText';
import Image from 'next/image';

const Quote = ({
  text,
  title,
  disclaimer,
  className,
}: {
  text: any;
  title: string;
  disclaimer: string;
  className?: string;
}) => {
  if (!text) {
    return null;
  }

  return (
    <div className={className}>
      <h5 className=" font-display text-headline-lg md:text-display-sm">{title}</h5>

      <div className=" mt-2xl md:mt-4xl bg-sys/surface/surface-container rounded-2xl px-2xl py-4xl">
        <HIHRichText value={text} />
        <div className="mt-4xl bg-sys/surface/surface-container-highest rounded-md p-xl flex items-start gap-x-md">
          <Image src="/icons/icon-info.svg" width={24} height={24} alt="Hinweis" />
          <p>{disclaimer}</p>
        </div>
      </div>
    </div>
  );
};

export default Quote;
