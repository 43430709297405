import React from 'react';
import { PortableText } from '@portabletext/react';
import { cn } from '@/utils/cn';
import { PortableTextBlock } from '@portabletext/types';
import RichTextComponents from './RichTextComponents';

interface RichTextProps {
  value: PortableTextBlock[];
  className?: string;
}

const HIHRichText: React.FC<RichTextProps> = ({ value, className }) => {
  return (
    <div className={cn(' break-words', className)}>
      <PortableText value={value} components={RichTextComponents} />
    </div>
  );
};

export default HIHRichText;
