'use client';

import { TeaserAlways as TeaserAlwaysType } from '@/shared/types/teasers';
import { cn } from '@/utils/cn';
import HIHButton from '../base/Button';
import { URL_PAGE_ALWAYS } from '@/shared/constants';
import SingleArticleMobileFullViewCard from '../base/SingleArticleMobileFullviewCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useEffect, useState } from 'react';
import { updateSlidesOffset } from '@/utils/updateSlidesOffset';
import { ArticleCard } from '@/sanity/fetchArticles';
import React from 'react';

export default function TeaserAlways({
  teaser,
  className,
}: {
  teaser: TeaserAlwaysType;
  className?: string;
}) {
  const { title, copy, linkLabel } = teaser;
  const [slidesOffsetBefore, setSlidesOffsetBefore] = useState(16); // Default offset
  const titleParts = title.split(/(?<=,)/);

  // Set the Offset for the slider based on window width
  useEffect(() => {
    updateSlidesOffset(setSlidesOffsetBefore); // Update on mount
    window.addEventListener('resize', () => updateSlidesOffset(setSlidesOffsetBefore)); // Add resize listener

    // Cleanup function to remove the event listener on component unmount
    return () => {
      window.removeEventListener('resize', () =>
        updateSlidesOffset(setSlidesOffsetBefore),
      );
    };
  }, []); // Empty dependency array to run only on mount and unmount

  return (
    <div className={cn(className)}>
      <div className="flex justify-between gap-x-9xl container-content">
        <div className="flex justify-between w-full flex-col lg:flex-row lg:gap-xl">
          <div className="font-display text-headline-lg lg:text-display-lg uppercase max-w-[550px]">
            {titleParts.map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index < titleParts.length - 1 && (
                  <>
                    <br />
                  </>
                )}{' '}
                {/* Add a line break after the part if it's not the last segment */}
              </React.Fragment>
            ))}
          </div>
          <div className="max-w-[488px] flex flex-col items-start lg:items-end h-full justify-between">
            <div className="inline-flex order-last lg:order-first justify-start items-start pt-4xl lg:pt-xl">
              <HIHButton link={URL_PAGE_ALWAYS} title={linkLabel} variant="secondary" />
            </div>
            <div className=" text-body-md lg:text-body-lg pt-xl lg:pt-0 lg:pb-[4px] text-left lg:text-right xl:text-left">
              {copy}
            </div>
          </div>
        </div>
      </div>

      {teaser.articles.length > 0 && (
        <div className=" pt-6xl">
          <div className="hidden lg:grid grid-cols-3 container-content gap-4xl">
            {teaser.articles.map((article: ArticleCard) => (
              <SingleArticleMobileFullViewCard key={article._id} article={article} />
            ))}
          </div>

          <div className="lg:hidden">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={16}
              className=" w-full swiper"
              slidesOffsetBefore={slidesOffsetBefore}
              slidesOffsetAfter={16}
            >
              {teaser.articles.map((article) => (
                <SwiperSlide key={article._id} className=" !w-[320px] pb-xs">
                  <div className="">
                    <SingleArticleMobileFullViewCard article={article} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
}
