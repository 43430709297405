'use client';

import { TeaserSocial as TeaserSocialType } from '@/shared/types/teasers';
import { cn } from '@/utils/cn';
import HIHButton from '../base/Button';
import Heart from '../base/Heart';
import HIHImage from '../base/Image';
import TripleLines from '../base/TripleLines';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import { ImageMeta } from '@/shared/types/imageMeta';
import { ReactNode } from 'react';
import HIHLink from '../base/Link';

export default function TeaserSocial({
  teaser,
  className,
}: {
  teaser: TeaserSocialType;
  className?: string;
}) {
  const { title, copy, postings } = teaser;

  const getSinglePosting = (
    posting: { _key: string; link: string; image: ImageMeta },
    index: number,
    breakpoint: 'sm' | 'md' | 'lg',
  ) => {
    const isSpecial = index === 0 || index === postings.length - 1;
    const width = breakpoint == 'lg' ? 280 : breakpoint == 'md' ? 198 : 132;
    const height = breakpoint == 'lg' ? 331 : breakpoint == 'md' ? 234 : 156;
    const style = `w-[${width}px] h-[${height}px]`;

    const image = (
      <HIHImage
        image={posting.image}
        width={width}
        height={height}
        className={cn('rounded-[7.541px] w-full  lg:w-[280px] lg:h-[331px]')}
      />
    );
    const content = posting.link ? (
      <HIHLink key={posting._key || index} href={posting.link} target="_blank">
        {image}
      </HIHLink>
    ) : (
      image
    );

    return (
      <div key={index}>
        {isSpecial ? (
          <TripleLines
            key={posting._key || index}
            variant={index === 0 ? 'topLeft' : 'bottomRight'}
            isLargeSize
            className="lg:w-auto"
            isContentFullWidth={true}
          >
            <div
              className={`!w-full'
          ${
            breakpoint === 'lg' &&
            (index === 0
              ? '-rotate-[4deg] mr-[12px] mt-[8px]'
              : 'rotate-[4deg] ml-[12px] mt-[8px]')
          }`}
            >
              {content}
            </div>
          </TripleLines>
        ) : (
          content
        )}
      </div>
    );
  };

  function postingList(breakpoint: 'sm' | 'md' | 'lg'): ReactNode {
    if (breakpoint !== 'lg') {
      return (
        <div className="flex flex-row w-full">
          <Swiper
            modules={[Scrollbar]}
            spaceBetween={8}
            slidesPerView={'auto'}
            className="w-full"
            slidesOffsetBefore={24}
            slidesOffsetAfter={24}
          >
            {postings.map((posting, index) => (
              <SwiperSlide
                key={posting._key || index}
                className="py-3xl lg:py-5xl min-w-[132px] !w-[30vw] lg:!w-full"
              >
                {getSinglePosting(posting, index, breakpoint)}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row gap-2xl py-3xl lg:py-5xl">
          {postings.map((posting, index) => getSinglePosting(posting, index, breakpoint))}
        </div>
      );
    }
  }

  return (
    <div className={className}>
      <div className={cn('flex flex-col items-center')}>
        <div className="flex flex-row w-full justify-center overflow-hidden">
          <div className="font-display text-display-lg lg:text-display-xl max-w-[400px] lg:max-w-[690px] text-center uppercase z-10">
            {title}
          </div>
          <Heart amount={2} />
        </div>

        <div className="flex md:hidden w-full">{postingList('sm')}</div>
        <div className="hidden md:flex lg:hidden w-full">{postingList('md')}</div>
        <div className="hidden lg:flex flex-row">{postingList('lg')}</div>

        <p className="font-body text-title-md lg:text-title-lg max-w-[592px] px-[16px] lg:px-0 text-center pb-3xl lg:pb-[5xl]">
          {copy}
        </p>
        <HIHButton
          link="https://www.instagram.com/heuteinhamburg.byhaspa/"
          title="Folge uns auf Instagram"
          blank
        />
      </div>
    </div>
  );
}
