/* 
The updateSlidesOffset function calculates and sets the offset for swiper slides based on the current window width.
It uses a series of breakpoints to determine the appropriate offset values. This ensures a responsive layout where
the swiper slides are adjusted dynamically according to the screen size. 
*/

export const updateSlidesOffset = (
  setSlidesOffsetBefore: (value: number) => void,
  hasMaxWidth?: boolean,
): void => {
  const breakpoints = [
    { width: 1536, offset: 32, maxWidth: 1536 },
    { width: 1280, offset: 32, maxWidth: 1280 },
    { width: 1024, offset: 32, maxWidth: null },
    { width: 768, offset: 32, maxWidth: null },
    { width: 640, offset: 16, maxWidth: null },
  ];

  const windowWidth = window.innerWidth;
  let calculatedOffset = 16; // Default offset for the smallest screen

  // Find the appropriate breakpoint
  for (const breakpoint of breakpoints) {
    if (windowWidth > breakpoint.width) {
      if (hasMaxWidth && windowWidth > 1800) {
        calculatedOffset = 160;
        break;
      }
      calculatedOffset = breakpoint.maxWidth
        ? (windowWidth - breakpoint.width) / 2 + breakpoint.offset
        : breakpoint.offset;
      break; // Exit loop once the right breakpoint is found
    }
  }
  setSlidesOffsetBefore(calculatedOffset);
};
